<script>
  import "@vueform/multiselect/themes/default.css";
  import VueCookies from 'vue-cookies'
  import Layout from "../../../layouts/main.vue";
  import PageHeader from "@/components/page-header";
  import appConfig from "../../../../app.config";
  import Swal from "sweetalert2";
  import axios from 'axios';

  export default {
    page: {
      title: "",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    components: {
      Layout,
      PageHeader,
    },
    data() {
      return {
        title: this.$t("t-clients-title"),
        empty: false,
        items: [],
        page: 1,
        perPage: 8,
        pages: [],
        value: null,
        searchQuery: null,
        date: null,
        records: [],
        consentAccepted: false,
        consentType: null,
        displayInternal: null,
      };
    },
    computed: {
      internalTenantPresent() {
        return (this.records.filter((data) => {
          return data.internal
        }).length > 0)
      },
      displayedPosts() {
        return this.paginate(this.resultQuery);
      },
      resultQuery() {
        let displayedPosts = this.records;
        if (this.searchQuery) {
          const search = this.searchQuery.toLowerCase();
          displayedPosts = this.records.filter((data) => {
            return (
              data.name && data.name.toLowerCase().includes(search)
            );
          });
        }
        if (this.displayInternal) {
          displayedPosts = displayedPosts.filter((data) => {
            return data.internal
          });
        }
        return displayedPosts;
      },
    },
    watch: {
      records() {
        this.setPages();
      },
      searchQuery() {
        this.setPages();
      }
    },
    created() {
      this.setPages();
    },
    filters: {
      trimWords(value) {
        return value.split(" ").splice(0, 20).join(" ") + "...";
      },
    },

    methods: {
      setDisplayInternal(displayInternal) {
        this.displayInternal = displayInternal;
        this.setPages();
      },
      checkCanActivateTenants() {
        axios.post(process.env.VUE_APP_API_BASE_URL+`/management/v1.0.0/usergroup/can_activate`, {})
            .then((data) => {
              console.log(data);
              if (data.data.tenants > 0) {
                document.getElementById('activate_tenants').style.display = 'flex';
              }
            }).catch((er) => {
          console.log(er)
        });
      },
      activateTenants() {
        axios.post(process.env.VUE_APP_API_BASE_URL+`/management/v1.0.0/usergroup/activate`, {})
            .then(() => {
              location.reload();
            }).catch((er) => {
          console.log(er)
        });
      },
      handleActiveChange(usergroup_id, dataIntegrationReady, event) {
        if (event.target.checked && !dataIntegrationReady) {
          Swal.fire({
            icon: "info",
            title: this.$t('t-clients-active-error-title'),
            text: this.$t('t-clients-active-error-text'),
            showCancelButton: false,
            showConfirmButton: true,
            allowOutsideClick: true,
            allowEscapeKey: true,
            confirmButtonColor: "#13c56b",
            // willOpen: () => {
            //   Swal.showLoading();
            // },
          });
          event.target.checked = !event.target.value;
          return;
        }
        const data = {
          usergroup_id: usergroup_id,
          disabled: !event.target.checked,
        }
        axios.post(process.env.VUE_APP_API_BASE_URL+`/management/v1.0.0/usergroup/disable`, data)
            .then(() => {

            }).catch((er) => {
          console.log(er)
        });
      },
      handleSearchInput(event) {
        this.searchQuery = event.target.value;
      },
      giveConsent() {
        axios.post(process.env.VUE_APP_API_BASE_URL+'/management/v1.0.0/consent/accept', {}).then(() => {
          document.getElementById('consent-close-modal').click();
        }).catch((er) => {
          console.log(er)
        });
      },
      retrieveConsent() {
        axios.get(process.env.VUE_APP_API_BASE_URL+'/management/v1.0.0/consent/status').then((data) => {
          if (!data.data.consent) {
            this.consentType = data.data.type;
            let button = document.getElementById('consent-modal-btn');
            if (button) {
              button.click();
            }
          }
        }).catch((er) => {
          console.log(er)
        });
      },
      retrieveClients() {
        axios.get(process.env.VUE_APP_API_BASE_URL+'/management/v1.0.0/usergroup/list').then((data) => {
          Swal.close();
          this.records = [];
          this.empty = (data.data.usergroups.length === 0)
          data.data.usergroups.forEach((row) => {
            // const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep",
            //   "Oct", "Nov", "Dec"
            // ];
            // var dd = new Date(row.date)
            // row.date = dd.getDate() + " " + monthNames[dd.getMonth()] + ", " + dd.getFullYear();
            // row.image_src = 'https://api-node.themesbrand.website/fileupload/users_bucket/' + row.image_src;
            this.records.push(row)
          })
          this.records.sort((a,b) => b.created - a.created)
        }).catch((er) => {
          Swal.close();
          console.log(er)
        });
      },
      formatEpoch(epoch) {
        const newDate = new Date(epoch*1000);
        let options = {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric'
        };
        return newDate.toLocaleString(undefined, options);
      },
      editdata(data) {
        document.getElementById('exampleModalLabel').innerHTML = this.$t("t-clients-edit-title")
        document.getElementById('id').value = data.id
        document.getElementById('usergroupname').value = data.name;
        document.getElementById('useremail').style.display = 'none';
        document.getElementById('usergrouplang').style.display = 'none';
        document.getElementById('edit-btn').style.display = 'block';
        document.getElementById('add-btn').style.display = 'none'
      },
      updateorder() {
        const usergroupId = document.getElementById('id').value;
        const usergroupName = document.getElementById('usergroupname').value;
        const usergroup = {
          id: usergroupId,
          name: usergroupName,
        };
        axios.post(process.env.VUE_APP_API_BASE_URL+`/management/v1.0.0/usergroup/update`, usergroup)
            .then(() => {
              let result = this.records.findIndex(o => o.id === usergroupId);
              this.records[result].name = usergroupName
              // this.$refs.users.updateData();
              document.getElementById('closemodal').click();
              document.getElementById('useremail').value = "";
              document.getElementById('usergroupname').value = "";
            }).catch((er) => {
          console.log(er)
        });
      },
      resenddata(event) {
        Swal.fire({
          title: "Are you sure?",
          icon: "question",
          showCancelButton: true,
          cancelButtonColor: "#f46a6a",
          confirmButtonColor: "#34c38f",
          confirmButtonText: "Yes, send instructions",
        }).then((result) => {
          if (result.value) {
            const user = {
              id: event.id
            }
            axios.post(process.env.VUE_APP_API_BASE_URL+`/management/v1.0.0/user/resend`, user)
                .then(() => {

                }).catch((er) => {
              console.log(er)
            });
            Swal.fire("Done", "Instructions have been sent", "success");
          }
        });
      },
      impersonate(event, install = false) {
        Swal.fire({
          title: this.$t('t-loading-impersonation'),
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          willOpen: () => {
            Swal.showLoading();
          },
        });
        const usergroup = {
          id: event.id
        }
        axios.post(process.env.VUE_APP_API_BASE_URL+`/management/v1.0.0/usergroup/impersonate`, usergroup)
            .then((data) => {
              Swal.close();
              let session_id = data.data.id
              let domain = null;
              if (process.env.VUE_APP_STAGE === 'staging') {
                domain = "staging.bodyguard.io";
              } else if (process.env.VUE_APP_STAGE === 'production') {
                domain = "prod.bodyguard.io";
              }
              VueCookies.set('manage-session', session_id, "1m", "/", domain);
              if (install) {
                VueCookies.set('manage-session-visit', 'install', "1m", "/", domain);
              } else {
                VueCookies.set('manage-session-visit', 'configuration', "1m", "/", domain);
              }
              window.location.href = data.data.portal_url;
            }).catch((er) => {
          Swal.close();
          console.log(er)
        });
      },
      deletedata(event) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to restore lost data",
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#f46a6a",
          confirmButtonColor: "#34c38f",
          confirmButtonText: "Yes, delete client",
        }).then((result) => {
          if (result.value) {
            this.records.splice(this.records.indexOf(event), 1);
            const usergroup = {
              id: event.id
            }
            axios.post(process.env.VUE_APP_API_BASE_URL+`/management/v1.0.0/usergroup/delete`, usergroup)
                .then(() => {
                  let result = this.records.findIndex(o => o.id === usergroup.id);
                  delete this.records[result];
                  // this.$refs.users.updateData();
                }).catch((er) => {
              console.log(er)
            });
            Swal.fire("Done", "Client has been deleted", "success");
          }
        });
      },
      addorder() {
        const email = document.getElementById('useremail').value;
        const usergroupType = document.getElementById('usergrouptype').value;
        const usergroupName = document.getElementById('usergroupname').value;
        const usergroupLang = document.getElementById('usergrouplang').value;
        const usergroup = {
          name: usergroupName,
          type: usergroupType,
          lang: usergroupLang,
        };
        if (email !== "") {
          usergroup['email'] = email;
        }
        axios.post(process.env.VUE_APP_API_BASE_URL+`/management/v1.0.0/usergroup/create`, usergroup)
          .then((data) => {
            usergroup.id = data.data.id;
            usergroup.name = usergroupName;
            usergroup.superadmin = email;
            usergroup.type = usergroupType;
            usergroup.created = Date.now() / 1000
            usergroup.disabled = Date.now() / 1000
            this.records.unshift(usergroup);
            // this.$refs.users.updateData();
          }).catch((er) => {
            console.log(er)
          });
        document.getElementById('closemodal').click();
        document.getElementById('useremail').value = "";
        document.getElementById('usergroupname').value = "";
      },
      addnew() {
        document.getElementById('exampleModalLabel').innerHTML = this.$t("t-clients-add-title");
        document.getElementById('useremail').style.display = 'none';
        document.getElementById('usergrouplang').style.display = 'none';
        document.getElementById('edit-btn').style.display = 'none';
        document.getElementById('add-btn').style.display = 'block';
      },
      setPages() {
        this.page = 1;
        let numberOfPages = Math.ceil(this.resultQuery.length / this.perPage);
        this.pages = [];
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      paginate(records) {
        let page = this.page;
        let perPage = this.perPage;
        let from = page * perPage - perPage;
        let to = page * perPage;
        return records.slice(from, to);
      },
    },
    mounted() {
    },
    beforeMount() {
      setTimeout(() => {
        Swal.fire({
          title: this.$t('t-loading'),
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          willOpen: () => {
            Swal.showLoading();
          },
        });
        this.retrieveClients();
        this.retrieveConsent();
        this.checkCanActivateTenants();
      }, 200);

    },

  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-header border-0">
            <div class="row g-4 align-items-center">
              <div class="col d-flex align-items-center">
                <p class="m-0 me-3" style="margin-left:20px !important;">{{ $t("t-clients-explainer") }}</p>
              </div>
              <div class="col-sm-auto ms-auto">
                <div class="hstack gap-2">
                  <button type="button" class="btn btn-success add-btn" id="create-btn" data-bs-toggle="modal"
                    href="#showModal" style="margin-right: 20px; margin-left: 20px;" @click="addnew">
                    <i class="ri-add-line align-bottom me-1"></i> {{ $t("t-clients-add-title") }}
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="d-flex gap-2 align-items-center" style="margin:20px 20px 10px 20px !important;">
                  <div class="search-box flex-grow-1 align-items-center">
                    <input @input="handleSearchInput" type="text" class="form-control search" :placeholder="$t('t-search')" />
                    <i class="ri-search-line search-icon"></i>
                  </div>
                  <div class="flex-shrink-0 ms-2" v-if="internalTenantPresent"><div class="dropdown card-header-dropdown"><a class="text-reset dropdown-btn" href="#" target="_self" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="text-muted">{{ displayInternal ? $t("t-clients-filter-internal") : $t("t-clients-filter-customers") }} <i class="mdi mdi-chevron-down ms-1"></i></span></a><div class="dropdown-menu dropdown-menu-end" style=""><a class="dropdown-item" href="#" target="_self" @click="setDisplayInternal(true)" v-if="!displayInternal">{{ $t("t-clients-filter-internal") }}</a><a class="dropdown-item" href="#" target="_self" @click="setDisplayInternal(false)" v-if="displayInternal">{{ $t("t-clients-filter-customers") }}</a></div></div></div>
<!--                  <div class="flex-shrink-0">-->
<!--&lt;!&ndash;                    <button class="btn btn-success btn-md" type="button">&lt;!&ndash;&ndash;&gt;<div class="btn-content"><i class="ri-equalizer-line align-bottom me-1"></i> Filters </div></button>&ndash;&gt;-->
<!--                  </div>-->
                </div>
                <b-alert show variant="primary" class="alert-border-left align-items-center" role="alert" style="margin-right: 20px; margin-left: 20px; display: none;" id="activate_tenants">
                  <div class="flex-shrink-0">
                    <i class="ri-information-line me-3 align-middle"></i>
                  </div>
                  <div class="flex-grow-1 text-truncate">
                    {{ $t("t-clients-activate-text") }}
                  </div>
                  <div class="flex-shrink-0">
                    <a href="#" @click="activateTenants" class="btn-sm m-0 btn-success">{{ $t("t-clients-activate-button") }}</a>
                  </div>
                </b-alert>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div>
              <div class="table-responsive table-card">
                <table class="table align-middle" id="customerTable">
                  <thead class="table-light">
                    <tr>
                      <th scope="col" style="width: 20px">
                      </th>

                      <th class="sort" data-sort="name">{{ $t("t-clients-name") }}</th>
                      <th class="sort" data-sort="created" style="width: 120px;">{{ $t("t-clients-added") }}</th>

<!--                      <th class="sort" data-sort="superadmin">{{ $t("t-clients-admin") }}</th>-->
<!--                      <th class="sort" data-sort="type">{{ $t("t-clients-type") }}</th>-->
                      <th class="sort text-center" data-sort="installation" style="width: 100px;">{{ $t("t-clients-installation") }}
<!--                        <i class="-->
<!--                                      bx-->
<!--                                      bxs-help-circle-->
<!--                                      fs-18-->
<!--                                      align-middle-->
<!--                                    " v-b-tooltip.hover :title="'<span style=\'text-align: left; font-weight: bold;\'>' + $t('t-clients-integration-explainer') + '</span>'"></i>-->
                      </th>
                      <th class="sort" data-sort="integratie" style="width: 160px;">{{ $t("t-clients-integration") }}
                        <i class="
                                      bx
                                      bxs-help-circle
                                      fs-18
                                      align-middle
                                    " v-b-tooltip.hover :title="'<span style=\'text-align: left; font-weight: bold;\'>' + $t('t-clients-integration-explainer') + '</span>'"></i>
                      </th>
                      <th class="sort text-center" data-sort="live" style="width: 80px;">{{ $t("t-clients-active") }}
                        <i class="
                                      bx
                                      bxs-help-circle
                                      fs-18
                                      align-middle
                                    " v-b-tooltip.hover :title="'<span style=\'text-align: left; font-weight: bold;\'>' + $t('t-clients-active-explainer') + '</span>'"></i>
                      </th>
                      <th class="sort" data-sort="action" style="width: 40px;">{{ $t("t-clients-portal") }}</th>
                      <th class="sort" data-sort="action" style="width: 80px;">{{ $t("t-clients-actions") }}</th>
                    </tr>
                  </thead>
                  <tbody class="list form-check-all">
                  <tr v-if="!displayedPosts.length">
                    <td></td>
                    <td colspan="7">{{ $t('t-clients-none') }}</td>
                  </tr>
                  <tr v-for="(data, index) of displayedPosts" :key="index">
                      <th scope="row">
                      </th>
                      <td class="id" style="display: none">
                        <a href="javascript:void(0);" class="fw-medium link-primary">{{ data.id }}</a>
                      </td>
                      <td class="cursor-pointer" @click="impersonate(data)">
                        {{ data.name }}
                      </td>
                    <td class="created cursor-pointer fs-12" @click="impersonate(data)">{{ formatEpoch(data.created) }}</td>
                    <td class="text-center">
                      <a v-if="!data.installed" @click="impersonate(data, true)">
                        <i class="bx bxs-download align-bottom text-muted cursor-pointer fs-18"></i>
                      </a>
                      <a v-if="data.installed">
                        <i class="ri ri-checkbox-circle-line align-bottom text-muted cursor-pointer fs-18"></i>
                      </a>
                    </td>
<!--                      <td class="cursor-pointer" @click="impersonate(data)">-->
<!--                        {{ data.superadmin }}-->
<!--                      </td>-->
<!--                      <td class="type cursor-pointer" @click="impersonate(data)">{{ data.type }}</td>-->
                      <td class="created cursor-pointer fs-12 text">
                        <span v-if="!data.data_integration_requested && !data.data_integration_completed">{{ $t('t-clients-integration-not-requested') }}</span>
                        <span v-if="data.data_integration_requested && !data.data_integration_completed">{{ $t('t-clients-integration-requested') }}</span>
                        <span v-if="data.data_integration_requested && data.data_integration_completed">{{ $t('t-clients-integration-completed') }}</span>
                      </td>
                      <td class="created cursor-pointer">
                        <div class="form-check form-switch form-switch-sm d-flex">
                          <input class="form-check-input m-auto" type="checkbox" role="switch" @change="handleActiveChange(data.id, (data.data_integration_requested && data.data_integration_completed), $event)" :checked="!data.disabled">
                        </div>
                      </td>
                      <td class="text-center cursor-pointer" @click="impersonate(data)">
                        <a>
                          <i class="mdi mdi-account align-bottom text-muted cursor-pointer fs-18"></i>
                        </a>
                      </td>
                      <td>
                        <ul class="list-inline hstack gap-2 mb-0">
                          <li class="list-inline-item" data-bs-toggle="modal" href="#showModal" data-bs-trigger="hover"
                            data-bs-placement="top" title="Edit" @click="editdata(data)">
                            <a class="edit-item-btn"><i class="ri-pencil-fill align-bottom text-muted cursor-pointer"></i></a>
                          </li>
                          <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover"
                            data-bs-placement="top" title="Delete" @click="deletedata(data)">
                            <a class="remove-item-btn" data-bs-toggle="modal">
                              <i class="ri-delete-bin-fill align-bottom text-muted cursor-pointer"></i>
                            </a>
                          </li>

                        </ul>
                      </td>

                  </tr>
                  </tbody>
                </table>
                <div class="d-flex justify-content-end p-3" v-if="!this.searchQuery">
                  <div class="pagination-wrap hstack gap-2">
                    <a class="page-item pagination-prev disabled" href="#" v-if="page != 1" @click="page--">
                      Previous
                    </a>
                    <ul class="pagination listjs-pagination mb-0">
                      <li :class="{
                          active: pageNumber == page,
                          disabled: pageNumber == '...',
                        }" v-for="(pageNumber, index) in pages.slice(
                          page - 1,
                          page + 5
                        )" :key="index" @click="page = pageNumber">
                        <a class="page" href="#">{{ pageNumber }}</a>
                      </li>
                    </ul>
                    <a class="page-item pagination-next" href="#" @click="page++" v-if="page < pages.length">
                      Next
                    </a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!--end col-->

    </div>


    <!--end row-->
    <div class="modal fade" id="showModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-light p-3">
            <h5 class="modal-title" id="exampleModalLabel"></h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
              id="close-modal"></button>
          </div>
          <div class="modal-body">
            <input type="hidden" id="id" />
            <div class="row g-3">
              <div class="col-lg-12">
                <div>
                  <input type="text" id="usergroupname" class="form-control" :placeholder="$t('t-clients-add-org')" required />
                  <br>
                  <input type="text" id="useremail" class="form-control" :placeholder="$t('t-clients-add-email')" />
                  <br>
                  <select type="text" id="usergrouptype" class="form-control d-none" required>
                    <option value="business">Business</option>
                  </select>

                  <select id="usergrouplang" required>
                    <option value="nl">nl</option>
                    <option value="en">en</option>
                  </select>
                </div>
              </div>
            </div>
            <!--end row-->
          </div>
          <div class="modal-footer">
            <div class="hstack gap-2 justify-content-end">
              <button type="button" class="btn btn-light" id="closemodal" data-bs-dismiss="modal">{{ $t("t-clients-add-close") }}</button>
              <button type="submit" class="btn btn-success" id="add-btn" @click="addorder">{{ $t("t-clients-add-yes") }}</button>
              <button type="button" class="btn btn-success" id="edit-btn" @click="updateorder">{{ $t("t-clients-update-yes") }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="consent-modal" tabindex="-1" aria-labelledby="consent-modal-label" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-light p-3">
            <h5 class="modal-title" id="consent-modal-label">{{ $t('t-consent-title') }}</h5>
            <button type="button" class="btn-close d-none" data-bs-dismiss="modal" aria-label="Close"
                    id="consent-close-modal"></button>
          </div>
          <div class="modal-body">
            <div class="row g-3">
              <div class="col-lg-12">
                <div>
                  <input type="checkbox" id="consent-checkbox" class="form-check-input" v-model="consentAccepted" />
                  <label v-if="consentType === 'msp'" for="consent-checkbox" class="form-check-label mx-1" v-html="$t('t-consent-description-msp')"></label>
                  <label v-if="consentType === 'reseller'" for="consent-checkbox" class="form-check-label mx-1" v-html="$t('t-consent-description-reseller')"></label>
                  <br>
                </div>
              </div>
            </div>
            <!--end row-->
          </div>
          <div class="modal-footer">
            <div class="hstack gap-2 justify-content-end">
              <button type="button" class="btn btn-success" id="accept-btn" @click="giveConsent" :disabled="!consentAccepted">{{ $t("t-consent-continue") }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>